import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  HostBinding,
  inject,
  input,
  signal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { matSelectAnimations } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { PlatformsService } from '@shared/platforms/services/platforms.service';
import { Platform } from '@shared/platforms/types/platform.type';
import { ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIcon, CdkOverlayOrigin, CdkConnectedOverlay, MatCheckbox, NgClass, MatTooltip],
  selector: 'platform-dropdown',
  animations: [matSelectAnimations.transformPanel],
  templateUrl: './platform-dropdown.component.html',
  styleUrl: './platform-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlatformDropdownComponent {
  private __router = inject(Router);
  private __toastrService = inject(ToastrService);
  private __platformsService = inject(PlatformsService);
  private __destroyRef = inject(DestroyRef);

  private __selectionControl = new FormControl<string[]>(undefined, [Validators.required]);
  private __selectionTmpControl = new FormControl<string[]>(undefined, [Validators.required]);
  private __$selectionControl = toSignal(this.__selectionControl.valueChanges);
  private __$selectionTmpControl = toSignal(this.__selectionTmpControl.valueChanges);

  protected _$allOptionEnabled = computed(() => !!this.__platformsService.$config()?.allowMultiple);
  protected _$showOverlay = signal(false);
  protected _$selectedCount = computed(() => (this.__platformsService.$platforms() || []).length);
  protected _$allAreSelectedCount = computed(() => +this.__$selectionTmpControl()?.length === +this._$platformsForSelection()?.length);
  protected _$selectedChips = computed(() => {
    const chipsLimit = this.$chipsLimit();
    const selected = this.__platformsService.$platforms();
    return selected.length > chipsLimit ? selected.slice(0, chipsLimit) : selected;
  });
  protected _$selectedOverLimit = computed(() => {
    const chipsLimit = this.$chipsLimit();
    return this.__platformsService.$platforms()?.length > chipsLimit
      ? this.__platformsService
          .$platforms()
          .slice(chipsLimit)
          .map((el) => el.label)
      : [];
  });
  protected _$platformsForSelection = computed(() => {
    const config = this.__platformsService.$config();
    let preferredPlatforms: (Platform & {
      selected?: boolean;
      index?: number;
    })[] = this.__platformsService.$platformsPreferred();

    if (!!config.isAD) {
      preferredPlatforms = preferredPlatforms.filter((platform) => !environment.platforms.unavailableForAD.includes(platform.name));
    }
    if (!config.isAccounts) {
      preferredPlatforms = preferredPlatforms.filter((platform) => !environment.platforms.availableOnlyForAccounts.includes(platform.name));
    }

    const selectedPlatforms = this.__platformsService.$platforms();
    return preferredPlatforms.map(
      (
        platform: Platform & {
          selected?: boolean;
          beta?: boolean;
          failedAccountsBadge?: boolean;
          blocked?: boolean;
        }
      ) => (
        (platform.blocked = selectedPlatforms.length === 1),
        (platform.selected = !!selectedPlatforms.find((p) => p.name === platform.name)),
        platform
      )
    );
  });
  protected _$selectionChanged = computed(() => {
    const selected = this.__$selectionControl();
    const selectedTmp = this.__$selectionTmpControl();
    return selected?.length !== selectedTmp?.length || selected?.some((platform) => !selectedTmp?.includes(platform));
  });

  readonly $chipsLimit = input(5, {
    alias: 'chips-limit'
  });

  constructor() {
    effect(() => {
      this.__$selectionTmpControl();
      this.__platformsService.$config().useSelection && this.__updateURL();
    });

    afterNextRender(() => {
      if (!this.__$selectionControl()) {
        const platforms = this.__platformsService.$platforms().map((p) => p.name);
        this.__selectionTmpControl.setValue(platforms, { emitEvent: false });
        this.__selectionControl.setValue(platforms, { emitEvent: false });

        this.__updateValueAndValidity(true);
      }

      this.__selectionControl.valueChanges
        .pipe(takeUntilDestroyed(this.__destroyRef))
        .subscribe((platforms) =>
          this.__platformsService.setPlatforms(
            ...this._$platformsForSelection().filter((p) => platforms.find((platformName) => platformName === p.name))
          )
        );
    });

    this.__router.events.pipe(takeUntilDestroyed()).subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd && this.__platformsService.$config().useSelection) {
        const platforms = this.__platformsService.$platforms().map((p) => p.name);
        this.__selectionControl.setValue(platforms, { emitEvent: true });
        this.__selectionTmpControl.setValue([...platforms], { emitEvent: true });
        this.__updateURL();
      }
    });
  }

  @HostBinding('class')
  protected get _hostCssClass(): string[] {
    const cssClass = [];
    !this.__platformsService.$config().useSelection && cssClass.push('d-none');
    return cssClass;
  }

  private __updateURL(): void {
    const url = new URL(window.location.href);
    url.searchParams.set(
      'platforms',
      this.__platformsService
        .$platforms()
        .map((p) => p.name)
        .join(',')
    );
    window.history.replaceState({}, '', url.toString());
  }

  protected _close(emitEvent = false): void {
    this._$showOverlay.set(false);

    this.__selectionTmpControl.setValue(this.__selectionControl.value, { emitEvent: false });

    this._$platformsForSelection().forEach((p) => (p.selected = this.__selectionControl.value?.includes(p.name)));

    this.__updateValueAndValidity(emitEvent);
  }

  private __updateValueAndValidity(emitEvent = false): void {
    this.__selectionTmpControl.markAsPristine();
    this.__selectionTmpControl.markAsUntouched();
    this.__selectionTmpControl.updateValueAndValidity();

    this.__selectionControl.markAsPristine();
    this.__selectionControl.markAsUntouched();
    this.__selectionControl.updateValueAndValidity({ emitEvent });
  }

  protected _togglePlatform(platform?: Platform & { selected?: boolean }): void {
    const selectedPlatforms = this._$platformsForSelection().filter((p) => p.selected);
    if (selectedPlatforms.length === 1 && platform?.selected) {
      this.__toastrService.warning('You must have at least one platform selected');
      return;
    }

    if (this._$allOptionEnabled()) {
      const platformIndex = this.__selectionTmpControl.value.findIndex((platformName) => platformName === platform.name);

      if (platformIndex === -1) {
        this.__selectionTmpControl.setValue([platform.name, ...this.__selectionTmpControl.value]);
      } else {
        this.__selectionTmpControl.setValue(this.__selectionTmpControl.value.filter((platformName) => platformName !== platform.name));
      }
    } else {
      this.__selectionTmpControl.setValue([platform.name]);
    }

    this._$platformsForSelection().forEach((p) => (p.selected = this.__selectionTmpControl.value?.includes(p.name)));
    this.__selectionTmpControl.markAsDirty();
  }

  protected _selectPlatform(platform?: Platform): void {
    this._$platformsForSelection().forEach((p) => (p.selected = p.name === platform.name));
    this.__selectionTmpControl.setValue([platform.name]);
    this.__selectionTmpControl.markAsDirty();
  }

  protected _selectAllPlatforms(): void {
    const platforms = this._$platformsForSelection().map((p) => ((p.selected = true), p.name));
    this.__selectionTmpControl.setValue(platforms);
    this.__selectionTmpControl.markAsDirty();
  }

  protected _applyPlatforms(): void {
    this.__selectionControl.setValue(this.__selectionTmpControl.value, { emitEvent: false });
    this._close(true);
  }
}
