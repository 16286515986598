import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccountsSharedModule } from '@shared/accounts/accounts-shared.module';
import { ActivityLogsSharedModule } from '@shared/activity-logs/activity-logs-shared.module';
import { AnimationsSharedModule } from '@shared/animations/animations-shared.module';
import { AnomalyDetectionSharedModule } from '@shared/anomaly-detection/anomaly-detection-shared.module';
import { AuthSharedModule } from '@shared/auth-module/auth-shared.module';
import { BatchActionsModule } from '@shared/batch-actions/batch-actions.module';
import { BestPracticeSharedModule } from '@shared/best-practice/best-practice-shared.module';
import { BusinessStructureSharedModule } from '@shared/business-structure/business-structure-shared.module';
import { ClientsSharedModule } from '@shared/clients/clients-shared.module';
import { CommonSharedModule } from '@shared/common/common-shared.module';
import { CSVSharedModule } from '@shared/csv/csv.module';
import { GovernanceSharedModule } from '@shared/governance/governance.module';
import { GlobalLoaderSharedModule } from '@shared/loader/global-loader.module';
import { NamingConventionsSharedModule } from '@shared/naming-conventions/naming-conventions-shared.module';
import { NgxTableModule } from '@shared/ngx-table/ngx-table.module';
import { NgxDropdownModule } from '@shared/ngxdropdown/ngxdropdown.module';
import { PageUtilsSharedModule } from '@shared/page-utils/page-utils-shared.module';
import { PlatformsSharedModule } from '@shared/platforms/platforms-shared.module';
import { PopupsSharedModule } from '@shared/popups/popups-shared.module';
import { QaChecklistsSharedModule } from '@shared/qa-checklists/qa-checklists-shared.module';
import { RouteChangeSharedModule } from '@shared/route-change/route-change-shared.module';
import { UserSharedModule } from '@shared/users-module/user-shared.module';
import { ValueTablesSharedModule } from '@shared/value-tables/value-tables-shared.module';

@NgModule({
  imports: [
    CommonSharedModule.forRoot(),
    AuthSharedModule.forRoot(),
    UserSharedModule.forRoot(),
    ClientsSharedModule.forRoot(),
    BusinessStructureSharedModule.forRoot(),
    RouteChangeSharedModule.forRoot(),
    ValueTablesSharedModule.forRoot(),
    PopupsSharedModule.forRoot(),
    NgxDropdownModule.forRoot(),
    PlatformsSharedModule.forRoot(),
    PageUtilsSharedModule.forRoot(),
    GlobalLoaderSharedModule.forRoot(),
    BestPracticeSharedModule.forRoot(),
    GovernanceSharedModule.forRoot(),
    AccountsSharedModule.forRoot(),
    NamingConventionsSharedModule.forRoot(),
    AnomalyDetectionSharedModule.forRoot(),
    CSVSharedModule.forRoot(),
    ActivityLogsSharedModule.forRoot(),
    AnimationsSharedModule.forRoot(),
    NgxTableModule.forRoot(),
    QaChecklistsSharedModule.forRoot(),
    BatchActionsModule.forRoot()
  ],
  exports: [BusinessStructureSharedModule, GlobalLoaderSharedModule]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
