import { inject, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as matIconSvg from '@environments/mat-icon-svg';

@NgModule({})
export class MatIconSvgModule {
  private __matIconRegistry = inject(MatIconRegistry);
  private __domSanitizer = inject(DomSanitizer);

  constructor() {
    Object.entries(matIconSvg.default).forEach(([name, url]) =>
      this.__matIconRegistry.addSvgIcon(name, this.__domSanitizer.bypassSecurityTrustResourceUrl(url))
    );
  }
}
