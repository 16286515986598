@if ({ group: $group(), items: $items(), params: $routeParams() }; as scope) {
  @if (scope.group.route) {
    <a
      class="d-flex align-items-center py-4 pe-4 text-decoration-none color-animate fs-16 lh-24 icon when-expanded"
      [ngClass]="{ 'w-max-26': !layout.$sidenavExpanded() }"
      routerLinkActive="active"
      [routerLink]="scope.group.route(scope.params)"
    >
      <span class="text-truncate ps-8 when-expanded">{{ scope.group.label }}</span>
      @if (showFailedAccountsBadge(scope.group.label)) {
        <app-failed-accounts-badge class="ms-auto" [$accountsErrors]="$accountsErrors()" [$type]="failureType.TOTAL" />
      }
    </a>
    <div class="line" [ngClass]="{ 'no-line': $isAccountsPage() }"></div>
  } @else {
    <div
      class="d-flex align-items-center py-8 color-fonts--note-100 color-animate fs-16 lh-24 icon when-expanded"
      [ngClass]="{ 'w-max-26': !layout.$sidenavExpanded(), 'color-fonts--secondary--hover cursor-pointer': scope.items?.length }"
      (click)="toggle()"
    >
      <span class="group-label text-truncate ps-8 when-expanded">{{ scope.group.label }}</span>
      @if (showFailedAccountsBadge(scope.group.label)) {
        <app-failed-accounts-badge class="ms-auto" [$accountsErrors]="$accountsErrors()" [$type]="failureType.TOTAL" />
      }
      @if (scope.items?.length) {
        <mat-icon class="w-min-24 ms-auto icon__toggle when-expanded" [matTooltip]="$isExpanded() ? 'Collapse' : 'Expand'"
          >expand_less</mat-icon
        >
      }
    </div>
    @if (scope.items?.length) {
      <div class="items">
        <ul>
          @for (item of scope.items; track item.label) {
            <li
              class="d-flex align-items-center position-relative fs-16"
              [matTooltip]="item.tooltip ? item.tooltip : ''"
              matTooltipPosition="above"
            >
              <a
                class="d-block text-decoration-none color-animate text-truncate ps-8 py-8 pe-8"
                [ngClass]="{ disabled: item.disabled }"
                routerLinkActive="active"
                [routerLink]="item.route(scope.params)"
                >{{ item.label }}
              </a>
            </li>
          }
        </ul>
      </div>
      <div class="line" [ngClass]="{ 'last-child': $isLastChild() }"></div>
    }
  }
  @if (scope.items?.length) {
    <div
      class="items__dropdown shadow-3 bg-color-white px-28 py-18 rounded-4 d-flex flex-column"
      [ngClass]="{ 'was-left': $wasLeft() }"
      (mouseout)="$wasLeft.set(true)"
    >
      <div>
        <div></div>
      </div>
      <h4 class="color-fonts--primary fs-16 lh-24 text-truncate m-0 p-0 pb-8">{{ scope.group.label }}</h4>
      <ul>
        @for (item of scope.items; track item.label) {
          <li
            class="d-flex align-items-center position-relative"
            [matTooltip]="item.tooltip ? item.tooltip : ''"
            matTooltipPosition="above"
          >
            <a
              class="d-block text-decoration-none color-animate text-truncate ps-8 py-8"
              routerLinkActive="active"
              [ngClass]="{ disabled: item.disabled }"
              [routerLink]="item.route(scope.params)"
              >{{ item.label }}
            </a>
          </li>
        }
      </ul>
    </div>
  }
}
