import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ClientsOutletLayoutComponent } from '@layouts/clients-outlet-layout/clients-outlet-layout.component';
import { ClientLayoutHeaderComponent } from '@layouts/clients-outlet-layout/components/client-layout-header/client-layout-header.component';
import { ClientLayoutSideNavComponent } from '@layouts/clients-outlet-layout/components/client-layout-side-nav/client-layout-side-nav.component';
import { MainOutletLayoutComponent } from '@layouts/main-outlet-layout/main-outlet-layout.component';
import { MarketingOutletLayoutComponent } from '@layouts/marketing-outlet-layout/marketing-outlet-layout.component';
import { DisabledAccountsPopupComponent } from '@shared/accounts/components/disabled-accounts-popup/disabled-accounts-popup.component';
import { BatchActionsModule } from '@shared/batch-actions/batch-actions.module';
import { ModalComponent } from '@shared/modals/components/modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ModalComponent,
    MatButtonModule,
    DisabledAccountsPopupComponent,
    BatchActionsModule,
    ClientLayoutHeaderComponent,
    ClientLayoutSideNavComponent
  ],
  declarations: [MarketingOutletLayoutComponent, ClientsOutletLayoutComponent, MainOutletLayoutComponent],
  exports: [MarketingOutletLayoutComponent, ClientsOutletLayoutComponent, MainOutletLayoutComponent]
})
export class LayoutsModule {}
