<div class="octra-dropdown__trigger" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  @for (platform of _$selectedChips(); track platform.name) {
    <div
      class="octra-dropdown__chip"
      [matTooltip]="platform.label"
      matTooltipPosition="below"
      matTooltipClass="mat-tooltip-nowrap octra-dropdown__item__tooltip"
    >
      <img src="assets/platforms/{{ platform.name }}.svg" [attr.alt]="platform.label" />
    </div>
  }
  @if (_$selectedOverLimit().length > 0) {
    <div class="octra-counter octra-counter--no-hover" [matTooltip]="_$selectedOverLimit().join(', ')">
      +{{ _$selectedOverLimit().length }}
    </div>
  }
  <div class="w-8 w-min-8"></div>
  <button mat-icon-button mat-small-button (click)="_$showOverlay.set(!_$showOverlay())">
    <mat-icon>expand_more</mat-icon>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="octra-dropdown__overlay"
  cdkConnectedOverlayPanelClass="octra-dropdown__panel"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="_$showOverlay()"
  (backdropClick)="_close()"
>
  <div [@transformPanel]="'showing'">
    @if (_$allOptionEnabled()) {
      <div class="header">
        <a
          [ngClass]="{ 'color-fonts--disabled cursor-default': _$allAreSelectedCount() }"
          (click)="!_$allAreSelectedCount() && _selectAllPlatforms()"
          >Select all</a
        >
      </div>
    }
    <div class="content">
      @for (platform of _$platformsForSelection(); track platform.name) {
        <div
          class="octra-tree__node"
          [ngClass]="{
            'octra-tree__node--selected': platform.selected
          }"
        >
          <mat-checkbox [checked]="platform.selected" (change)="_togglePlatform(platform)">
            <div class="d-flex align-items-center">
              <img class="w-20 w-min-20 h-20 h-min-20 h-max-2" src="assets/platforms/{{ platform.name }}.svg" />
              <span>{{ platform.label }}</span>
            </div>
          </mat-checkbox>
          @if (_$allOptionEnabled()) {
            <div class="ms-auto show-on-hover">
              <button mat-button mat-link-button mat-small-button (click)="_selectPlatform(platform)">
                <span class="font-weight-400">Only</span>
              </button>
            </div>
          }
        </div>
      }
    </div>
    <div class="actions">
      <button mat-raised-button mat-button-s [disabled]="!_$selectionChanged()" (click)="_applyPlatforms()">Apply</button>
    </div>
  </div>
</ng-template>
