import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { AccountErrors } from '@shared/accounts/types/account-errors.type';

export enum FailureType {
  TOTAL = 'total',
  METRIC = 'metric',
  SETTINGS = 'settings'
}

@Component({
  selector: 'app-failed-accounts-badge',
  templateUrl: './failed-accounts-badge.component.html',
  styleUrls: ['./failed-accounts-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FailedAccountsBadgeComponent {
  $type = input<FailureType>(FailureType.TOTAL);
  $accountsErrors = input.required<AccountErrors[]>();

  $failedAccountsCount = computed(() => {
    const accountsErrors = this.$accountsErrors();
    return this.$type() === FailureType.TOTAL ? this.__getAccountsWithErrors(accountsErrors) : 0;
  });

  private __getAccountsWithErrors(accountsErrors: AccountErrors[]): number {
    return accountsErrors.reduce((accountsWithErrors, accountErrors) => {
      return accountsWithErrors + accountErrors.accounts_with_errors;
    }, 0);
  }
}
