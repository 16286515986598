<button
  mat-icon-button
  (click)="layout.toggleSidenav()"
  [matTooltip]="layout.$sidenavExpanded() ? 'Hide side navigation' : 'Show side navigation'"
>
  @if (layout.$sidenavExpanded()) {
    <mat-icon>menu_open</mat-icon>
  } @else {
    <mat-icon>menu</mat-icon>
  }
</button>

<div class="header__logo" [ngClass]="{ 'd-none': isIframe }">
  <img src="/assets/octra.svg" />
</div>
<div class="me-72" [ngClass]="{ 'd-none': !isIframe }"></div>

<business-unit-navigation [ngClass]="{ 'd-none': !_pageWithClientSlug() }" />

<platform-dropdown [ngClass]="{ 'd-none': !_pageWithClientSlug() }" class="ms-24" />

<span class="ms-auto"></span>

@if (!!_user.impersonator) {
  <div class="header__impersonate">
    <mat-icon class="outlined header__impersonate__icon">warning</mat-icon>
    Impersonate mode
    <div class="header__impersonate__btn" (click)="v1_stopImpersonation()" matTooltip="Stop impersonation">Exit</div>
  </div>
}

<button [routerLink]="APP_ROUTES.admin.clients()" mat-icon-button color="primary" aria-label="home" matTooltip="Clients list">
  <mat-icon class="outlined">home</mat-icon>
</button>

<a href="https://insidemedia.sharepoint.com/sites/OCTRAKnowledgeBase" target="_blank" mat-icon-button matTooltip="Help & Feedback">
  <mat-icon class="outlined">help</mat-icon>
</a>

@if (_user) {
  <div class="header__profile__btn" [ngxdropdowntoggle]="profilePopup">
    <div class="octra-user" [picture]="_user.picture" [initials]="_user.name"></div>
    <mat-icon>expand_more</mat-icon>
  </div>
}

<div
  ngxdropdown
  [ngxdropdownoptions]="{
    customOverlayClass: 'profile-dropdown__overlay',
    position: 'bottomleft',
    showOverlay: true,
    hideOnOverlayClick: true,
    edgePadding: 8
  }"
  #profilePopup="ngxdropdown"
  class="profile-dropdown"
>
  <div class="octra-user" [picture]="_user.picture">
    <label>{{ _user.name }} </label>
    <summary>{{ _user.email }}</summary>
  </div>
  <a class="octra-list__item" [routerLink]="APP_ROUTES.profile.settings()">
    <mat-icon class="outlined">settings</mat-icon> User profile settings
  </a>
  <button type="button" class="octra-list__item" (click)="logout()"><mat-icon>logout</mat-icon> Sign out</button>
  <div class="profile-dropdown__footer">
    Octra {{ $dateNow() | date: 'YYYY' }}. All rights reserved. <a [routerLink]="APP_ROUTES.privacyPolicy()">Privacy Policy</a>
  </div>
</div>
